.carousel-product-image {
    width: 100%; /* Slightly larger than the container */
    height: 90vh; /* Sets a max height */
    object-fit: contain; /* Keeps the entire image visible within the bounds */
    margin: 0 auto; /* Centers the image */
}

@media (max-width: 768px) { 
    .carousel-product-image {
        width: 95vw; /* Slightly larger width on mobile */
        height: auto; /* Keeps the aspect ratio */
        object-fit: contain;
    }
}

.product-description-text {
    font-size: 15px;
}

@media (max-width: 768px) { 
    .product-description-text {
        font-size: 10px;
    }
}

.selectable-variants {
    margin-top: 2vh;
}

@media (max-width: 768px) {
    .selectable-variants {
        margin-top: 0vh;
    }
}