.ofc-width {
    width: 75% !important;
}

  /* Small screens (phones, etc.) */
  @media (max-width: 600px) {
    .ofc-width {
        width: 75% !important;
    }
}

/* Medium screens (tablets, etc.) */
@media (min-width: 601px) and (max-width: 1024px) {
    .ofc-width {
        width: 50% !important;
    }
}

/* Large screens (desktops, etc.) */
@media (min-width: 1025px) {
    .ofc-width {
        width: 30% !important;
    }
}

.cart-drawer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.cart-drawer-header {
    border-bottom: 1px solid #ddd;
}

.cart-drawer-footer {
    margin-top: auto;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

.subtotal-row {
    text-align: right;
}

.cart-drawer-font {
    font-family: "RepublicaMinor2";
}