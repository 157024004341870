.top-menu-bar {
    position: fixed;
    z-index: 1000;
}

.top-menu-bar-row {
  height:8vh;
}


@media (max-width: 768px)
{
  .top-menu-bar-row {
    height: 5vh;
  }
}

.menu-icon-div {
    cursor: pointer;
  }
  
  .menu-icon-div:hover {
    opacity: 0.5;
  }
  
  .menu-icon {
    width: 2vw;
    margin-right: 18px;
  }

  /* Small screens (phones, etc.) */
  @media (max-width: 600px) {
    .menu-icon {
        width:4vw;
    }
}

.top-menu-logo {
  width: 30vw;
}

@media (max-width: 600px) {
  .top-menu-logo {
    width:60vw;
  }
}
  .menu-icon-text {
    /* text-decoration: none;
    color: inherit; */
    font-size: 14px;
    border-bottom: 1px solid black;
    color: black;
  }

  /* Small screens (phones, etc.) */
@media (max-width: 600px) {
    .menu-icon-text {
        font-size: 10px; /* Smaller size for small screens */
    }
}

/* Medium screens (tablets, etc.) */
@media (min-width: 601px) and (max-width: 1024px) {
    .menu-icon-text {
        font-size: 14px; /* Medium size for tablets */
    }
}

/* Large screens (desktops, etc.) */
@media (min-width: 1025px) {
    .menu-icon-text {
        font-size: 16px; /* Default or larger size for desktops */
    }
}
  
  .menu-home-link {
    text-decoration: none;
    color: inherit;
    font-size: 2.0vw;
  }

  @media (max-width: 768px) {
    .menu-home-link {
      font-size: 4.2vw;
    }
  }
  
  .menu-home-div {
    border-right: 1px solid black;
    border-left: 1px solid black;
  }

  .cart-icon-div:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  
  .menu-fonty {
    font-family: "RepublicaMinor2";
  }