.footer-bg {
    background-color: black;
}

.footer-font {
    font-family: "RepublicaMinor2";
}

.footer-social-link {
    color: white;
    text-decoration: none;
    font-size: 1.0vw;
}

@media (max-width: 768px) { 
  .footer-social-link {
    font-size: 2.0vw;
  }
}

.footer-social-logo {
    width: 2vw;
}

.footer-email-list-text {
    color: rgba(255, 255, 255, 1) !important;
    opacity: 1 !important;
    font-size: 1.7vw;
}

@media (max-width: 768px) {
  .footer-email-list-text {
    font-size: 2.8vw;
  }
}

.form-email-list-box-text {
  font-size: 1.5vw !important;
  background-color: black !important;
  color: white !important;
}

@media (max-width: 768px) {
  .form-email-list-box-text {
    font-size: 2.3vw !important;
  }
}


/* Ensure the placeholder text is solid white with !important */
.custom-placeholder::-webkit-input-placeholder {
    color: white !important;
    opacity: 1 !important;
  }
  
  .custom-placeholder:-moz-placeholder {
    color: white !important;
    opacity: 1 !important;
  }
  
  .custom-placeholder::-moz-placeholder {
    color: white !important;
    opacity: 1 !important;
  }
  
  .custom-placeholder:-ms-input-placeholder {
    color: white !important;
    opacity: 1 !important;
  }

  .footer-image-left {
    width: 18vw;
  }

  @media (max-width:768px) {
    .footer-image-left {
      width: 25vw;
    }
  }

  .footer-image-right {
    width: 20vw;
  }

  @media (max-width:768px) {
    .footer-image-right {
      width: 28vw;
    }
  }

  .form-styling {
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .position-relativeddd {
    position: relative;
  }
  
  .anchor-bottom-rightddd {
    position: absolute;
    padding-bottom: 5vh;
    padding-right: 5vw;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 768px) { 
    .anchor-bottom-rightddd {
        padding-bottom: 2vh;
        padding-right: 2vw;
    }
}