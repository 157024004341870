.about-page-col {
    position: relative;
}

.about-page-img {
    height: 100vh;
    display: block;
    margin-bottom: -7%;
}

/* Small screens (phones, etc.) */
@media (max-width: 600px) {
    .about-page-img {
        position: relative;
        bottom: 0;
        left: 0;
        height: auto;
        width: 100vw; /* Maintains aspect ratio */
        margin-top: 80%;
        margin-left: -10%;
        margin-bottom: -13%;
    }
}