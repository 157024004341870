.product-variant-dropdown {
  width: 15vw;
  height: 5vh;
}

@media (max-width: 768px) {
  .product-variant-dropdown {
    width: 40vw;
    height: 4vh;
  }
}

.add-to-cart-btn {
  width: 15vw;
  height: 5vh;
}

@media (max-width: 768px) { 
  .add-to-cart-btn {
      width: 40vw;
      height: 4vh;
  }
}

.soldout-text {
  font-size: 1.3rem; /* Default size */
}

@media (max-width: 768px) {
  .soldout-text {
    font-size: 1.2rem; /* Larger size for mobile screens */
  }
}