.image-container {
    position: relative;
}

.home-page-image {
    width: 50vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image-text {
    position: absolute;
    color: black;
    text-align: center;
    left: 0;
    right: 0;
    font-size: 12px;
}

/* Small screens (phones, etc.) */
@media (max-width: 600px) {
    .image-text {
        font-size: 12px; /* Smaller size for small screens */
    }
}

/* Medium screens (tablets, etc.) */
@media (min-width: 601px) and (max-width: 1024px) {
    .image-text {
        font-size: 22px; /* Medium size for tablets */
    }
}

/* Large screens (desktops, etc.) */
@media (min-width: 1025px) {
    .image-text {
        font-size: 24px; /* Default or larger size for desktops */
    }
}

.fonty {
    font-family: "RepublicaMinor2";
    font-size: 1.4vw;
  }