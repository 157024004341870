.product-list-page-container {
  padding-top: 10vh;
}

@media (max-width: 768px) {
  .product-list-page-container {
    padding-top: 6vh;
  }
}

body, html {
  overflow-x: hidden;
}

.product-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  cursor: pointer;
}

.product-card .card-body {
  flex-grow: 1;
}

.product-card .card-img-top {
  width: 100%; /* Ensures the image takes up the full width of its container */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain;
}

@media (max-width: 576px) {
  .product-card .card-img-top {
    height: 300px; /* Specific height for small screens */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .product-card .card-img-top {
    height: 400px; /* Specific height for tablets */
  }
}

@media (min-width: 769px) {
  .product-card .card-img-top {
    height: 600px; /* Specific height for larger screens */
  }
}

.card-title-text {
  font-size: 1vw !important;
}

@media (max-width: 768px) {
  .card-title-text {
    font-size: 3vw !important;
  }
}

.card-text-text {
  font-size: 1vw !important;
}

@media (max-width: 768px) {
  .card-text-text {
    font-size: 3vw !important;
  }
}