.responsive-image {
    width: 100%; /* Bootstrap's img-fluid class handles this, but for redundancy */
    height: auto; /* Maintain aspect ratio */
    max-height: 200px; /* Example max height, adjust as needed */
}

@media (min-width: 376px) {
    .responsive-image {
        max-height: 200px; /* Adjust for larger screens */
    }
}

@media (min-width: 576px) {
    .responsive-image {
        max-height: 100px; /* Adjust for larger screens */
    }
}

@media (min-width: 768px) {
    .responsive-image {
        max-height: 200px; /* Adjust for larger screens */
    }
}

@media (min-width: 992px) {
    .responsive-image {
        max-height: 300px; /* Adjust for larger screens */
    }
}

@media (min-width: 1200px) {
    .responsive-image {
        max-height: 400px; /* Adjust for larger screens */
    }
}

.stack-item {
    font-size: 14px;
}

@media (max-width: 768px) {
    .stack-item {
        font-size: 10px
    }
}

.custom-button {
    padding: 2px 5px; /* Adjust padding as needed */
    font-size: 16px !important;
    line-height: 1; /* Adjust line height as needed */
}

@media (max-width: 768px) {
    .custom-button {
        font-size: 11px !important;
    }
}

/* .cart-item-image {
    width: 11vw;
}

@media (max-width: 768px) {
    width: 11vw;
} */