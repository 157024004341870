.startup-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    font-family: "RepublicaMinor2";
}

.startup-image {
    width: 100vw;
    height: 100vh;
}

.overlay-text {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.overlay-text h1 {
    font-size: 3em;
    margin: 0;
}

.overlay-text p {
    font-size: 1.5em;
}

.password-form .password-input,
.password-form .password-submit {
  display: inline-block;
  vertical-align: middle;
}

.pwd-box {
    width: 30vw;
}

@media (max-width: 768px) {
    .pwd-box {
        width: 80vw;
    }
}

.button-container {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    opacity: 70%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjust values and color as needed */
}

@media (max-width: 768px) {
    .button-container {
        top: 80%
    }
}