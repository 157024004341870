.ofc-width {
    width: 75% !important;
}

  /* Small screens (phones, etc.) */
  @media (max-width: 600px) {
    .ofc-width {
        width: 75% !important;
    }
}

/* Medium screens (tablets, etc.) */
@media (min-width: 601px) and (max-width: 1024px) {
    .ofc-width {
        width: 50% !important;
    }
}

/* Large screens (desktops, etc.) */
@media (min-width: 1025px) {
    .ofc-width {
        width: 30% !important;
    }
}

/* Default text size */
.menu-collection-option {
    cursor: pointer;
    font-size: 18px; /* Default size */
}

/* Small screens (phones, etc.) */
@media (max-width: 600px) {
    .menu-collection-option {
        font-size: 12px; /* Smaller size for small screens */
    }
}

/* Medium screens (tablets, etc.) */
@media (min-width: 601px) and (max-width: 1024px) {
    .menu-collection-option {
        font-size: 16px; /* Medium size for tablets */
    }
}

/* Large screens (desktops, etc.) */
@media (min-width: 1025px) {
    .menu-collection-option {
        font-size: 18px; /* Default or larger size for desktops */
    }
}

.menu-collection-option:hover{
    opacity: 0.5;
}

.menu-drawer-font {
    font-family: "RepublicaMinor2";
}